const initMediaplatformPlayer = ((anywhereId, mediaplatformId) => {
  initMediaplatform(anywhereId, mediaplatformId);
});

const clickVideo = ((e) => {

  const click = (e.target.closest('.tns-outer.current-drag') === null);
  if (click) {
    const anywhereObject = e.target.closest('[anywhere-id][mediaplatform-id]');
    if (anywhereObject) {
      const anywhereId = anywhereObject.getAttribute('anywhere-id');
      const mediaplatformId = anywhereObject.getAttribute('mediaplatform-id');
      if (anywhereId && mediaplatformId) {
        initMediaplatformPlayer(anywhereId, mediaplatformId);
      }
    }
  }
});

const carouselDragStart = ((carousel) => {
  carousel
    .container
    .closest('.tns-outer')
    .classList.add('current-drag');
});

const carouselDragEnd = ((carousel) => {
  carousel
    .container
    .closest('.tns-outer')
    .classList.remove('current-drag');
});

const carouselTransitionEnd = carouselDragEnd;

const duration = (totalSeconds) => {

  hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  minutes = Math.floor(totalSeconds / 60);
  seconds = totalSeconds % 60;

  let duration = hours === 0 ? '' : (hours + ':');

  duration += minutes + ':' + seconds;
  return duration;

};

const initCarousel = ((carouselId, carouselConfig, anywhereVideos) => {
  const carouselElement = document.getElementById(carouselId);
  const carouselCardElement = carouselElement.children[0];

  anywhereVideos.forEach((video) => {
    let newCard = carouselCardElement.cloneNode(true);

    const currentVideo = {
      anywhereId: video.id,
      mediaplatformId: video.mediaData.entryId,
      thumbnail: video.mediaData.thumbnailUrl + '/width/500',
      releaseDate: new Date(video.publicationData.releaseFrom + 'Z'),
      duration: duration(video.metaData.VideoDuration),
      title: video.metaData.title,
    };

    newCard.setAttribute('anywhere-id', currentVideo.anywhereId);
    newCard.setAttribute('mediaplatform-id', currentVideo.mediaplatformId);
    // newCard.addEventListener('click', clickVideo);

    newCard.querySelector('.card-img').style.backgroundImage = `url(${currentVideo.thumbnail}`;

    const img = newCard.querySelector('img');
    img.setAttribute('src', currentVideo.thumbnail);
    img.setAttribute('attr', currentVideo.title);
    newCard.querySelector('.title').textContent = currentVideo.title;
    newCard.querySelector('.duration').textContent = currentVideo.duration;

    carouselElement.appendChild(newCard);
  });


  carouselCardElement.remove();

  carouselConfig.container = `#${carouselId}`;
  const carousel = tns(carouselConfig);
  
  carousel.events.on('dragMove', carouselDragStart);
  carousel.events.on('touchMove', carouselDragStart);
  carousel.events.on('dragEnd', carouselDragEnd);
  carousel.events.on('touchEnd', carouselDragEnd);

  $(`#${carouselId}`).on('click', '.tns-item', function(e){
    clickVideo(e);
  });

  $(`#${carouselId} .tns-item:first-child`).trigger('click');
});

const initSection = ((sectionId, sectionConfig, anywhereSections) => {
  const sectionElement = document.getElementById(sectionId);

  anywhereSections.forEach((anywhereSection) => {
    let newSection = sectionElement.cloneNode(true);

    newSection.setAttribute('id', `${sectionId}-${anywhereSection.id}`);

    const carouselId = `anywhere-carousel-${anywhereSection.id}`;
    Array.from(newSection.children).forEach((child, index) => {
      if (child.id === 'anywhere-carousel') {
        child.id = carouselId;
      }
    });

    sectionElement.parentNode.appendChild(newSection);

    initCarousel(carouselId, sectionConfig.carouselConfig, anywhereSection.itemData);
  });

  sectionElement.remove();
});
